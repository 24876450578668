import React from 'react';
import ReactDOM from 'react-dom';
import PortfolioRouter from './Routers/PorfolioRouter'
import * as serviceWorker from './serviceWorker';
import './index.less'

ReactDOM.render(
  <React.Fragment>
    <PortfolioRouter />
  </React.Fragment>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
